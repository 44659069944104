// Change this so it's split into Northern Sask, Central Sask, and Southern Sask
export const saskatchewan = {
    "Regina": "6445e63028982bf3fb686af1",
    "Saskatoon": "6445e68028982bf3fb686aff",
    "Swift Current": "6445e68528982bf3fb686b01",
    "Weyburn": "6445e68b28982bf3fb686b03",
    "Yorkton": "6445e68f28982bf3fb686b05",
    "Estevan": "6445e63628982bf3fb686af3",
    "Kindersley": "6445e63a28982bf3fb686af5",
    "Melville": "6445e64128982bf3fb686af7",
    "Moose Jaw": "6445e67028982bf3fb686af9",
    "North Battlefords": "6445e67528982bf3fb686afb",
    "Prince Albert": "6445e67b28982bf3fb686afd",
    "Assiniboia": "6445e28028982bf3fb686a72",
    "Broadview": "6445e31528982bf3fb686a74",
    "Carlyle": "6445e31c28982bf3fb686a76",
    "Canora": "6445e32328982bf3fb686a78",
    "Esterhazy": "6445e32a28982bf3fb686a7a",
    "Indian Head": "6445e33128982bf3fb686a7c",
    "Kamsack": "6445e33828982bf3fb686a7e",
    "Leader": "6445e34128982bf3fb686a80",
    "Maple Creek": "6445e34728982bf3fb686a82",
    "Moosomin": "6445e34e28982bf3fb686a84",
    "Outlook": "6445e35728982bf3fb686a86",
    "Rosetown": "6445e35f28982bf3fb686a88",
    "Shaunavon": "6445e36628982bf3fb686a8a",
    "Wadena": "6445e37228982bf3fb686a8c",
    "Wakaw": "6445e3a828982bf3fb686a8e",
    "Wynyard": "6445e3ae28982bf3fb686a90",
    "Beauval": "6445e3b428982bf3fb686a92",
    "Black Lake": "6445e3be28982bf3fb686a94",
    "Buffalo Narrows": "6445e3c728982bf3fb686a96",
    "Buffalo River Dene Nation": "6445e3cd28982bf3fb686a98",
    "Canoe Lake Cree First Nation": "6445e3d528982bf3fb686a9a",
    "Creighton": "6445e3d928982bf3fb686a9c",
    "Cumberland House Cree Nation": "6445e3df28982bf3fb686a9e",
    "Deschambault Lake": "6445e3e528982bf3fb686aa0",
    "Fond du Lac": "6445e3ea28982bf3fb686aa2",
    "Hudson Bay": "6445e3f128982bf3fb686aa4",
    "Ile a la Crosse": "6445e43728982bf3fb686aa6",
    "La Loche": "6445e43d28982bf3fb686aa8",
    "Montreal Lake Cree Nation": "6445e44328982bf3fb686aaa",
    "Pelican Narrows": "6445e44a28982bf3fb686aac",
    "Pinehouse": "6445e45028982bf3fb686aae",
    "Sandy Bay": "6445e45828982bf3fb686ab0",
    "Southend": "6445e45d28982bf3fb686ab2",
    "St. Walburg": "6445e46328982bf3fb686ab4",
    "Turnor Lake (Birch Narrows Dene Nation)": "6445e48e28982bf3fb686ab8",
    "Wollaston Lake": "6445e49728982bf3fb686aba",
    "Ahtahkakoop Cree Nation": "6445e49f28982bf3fb686abc",
    "Beardy's and Okemasis Cree Nation": "6445e4aa28982bf3fb686abe",
    "Biggar": "6445e4ba28982bf3fb686ac1",
    "Blaine Lake": "6445e4c428982bf3fb686ac3",
    "Carrot River": "6445e4cf28982bf3fb686ac5",
    "Cut Knife": "6445e4d728982bf3fb686ac7",
    "English River First Nation": "6445e4e128982bf3fb686ac9",
    "Humboldt": "6445e4ea28982bf3fb686acb",
    "Loon Lake": "6445e4f128982bf3fb686acd",
    "Nipawin": "6445e4f828982bf3fb686acf",
    "Onion Lake Cree Nation": "6445e4fd28982bf3fb686ad1",
    "Melfort": "6445e50428982bf3fb686ad3",
    "Meadow Lake": "6445e50928982bf3fb686ad5",
    "Lloydminster": "6445e50f28982bf3fb686ad7",
    "Pierceland": "6445e51828982bf3fb686ada",
    "Punnichy": "6445e51f28982bf3fb686adc",
    "Rose Valley": "6445e55b28982bf3fb686ade",
    "Rosthern": "6445e56d28982bf3fb686ae0",
    "Shellbrook": "6445e57828982bf3fb686ae3",
    "Spiritwood": "6445e58128982bf3fb686ae5",
    "Stanley Mission": "6445e58828982bf3fb686ae7",
    "Unity": "6445e59928982bf3fb686ae9",
    "Whitefish (Big River) First Nation": "6445e5e528982bf3fb686aeb",
    "La Ronge": "6445e5ee28982bf3fb686aed",
    "Fort Qu'Appelle": "6445e5f428982bf3fb686aef",
};


export const skProvincialCourtOffices = {
    "Estevan": ["Carlyle", "Weyburn"],
    "La Ronge": ["Black Lake", "Creighton", "Cumberland House", "Deschambault Lake", "Fond du Lac", "Pinehouse", "Southend", "Stanley Mission", "Wollaston Lake"],
    "Lloydminster": ["Onion Lake Cree Nation", "St. Walburg"],
    "Melfort": ["Carrot River", "Hudson Bay", "Nipawin", "Wakaw"],
    "Meadow Lake": ["Beauval", "Turnor Lake (Birch Narrows Dene Nation)", "Buffalo Narrows", "Buffalo River Dene Nation", "Canoe Lake Cree First Nation", "English River First Nation", "Ile a la Crosse", "La Loche", "Loon Lake", "Pierceland"],
    "Moose Jaw": ["Assiniboia"],
    "North Battlefords": ["Biggar", "Cut Knife", "Spiritwood", "Unity"],
    "Prince Albert": ["Ahtahkakoop Cree Nation", "Montreal Lake Cree Nation", "Pelican Narrows", "Sandy Bay", "Shellbrook", "Whitefish (Big River) First Nation"],
    "Regina": ["Fort Qu’Appelle", "Indian Head"],
    "Swift Current": ["Leader", "Maple Creek", "Shaunavon"],
    "Saskatoon": ["Beardy’s and Okemasis’ Cree Nation", "Blaine Lake", "Humboldt", "Kindersley", "Outlook", "Rosetown", "Rosthern"],
    "Wynyard": ["Punnichy", "Rose Valley", "Wadena"],
    "Yorkton": ["Broadview", "Canora", "Esterhazy", "Kamsack", "Melville", "Moosomin"],
}